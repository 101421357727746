import { PlusIcon } from '@heroicons/react/24/outline'
import { useState } from 'react'
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd'
import { useTranslation } from 'react-i18next'

import ApiResourceEdit from '@components/api-resource/api-resource-edit'
import GlobalSearch from '@components/global-search/global-search'
import Modal from '@components/modals/modal'
import Artist from '@components/pages/artist/artist'
import Experience from '@components/pages/experience/experience'
import { type RowListProps } from '@components/pages/row-list/row-list.interfaces'
import SubPage from '@components/pages/sub-page/sub-page'
import { type DroppableType } from '@interfaces/api/droppable'
import { SectionLimited } from '@interfaces/api/page/section'
import { type SectionRow } from '@interfaces/api/page/section-row'
import SkeletonLoader from '@root/components/skeleton-loader/skeleton-loader'
import useApiResource from '@services/api/definition'
import useCreateEntry from '@services/api/resources/create-entry-query'
import useDeleteEntrySimple from '@services/api/resources/delete-entry-query-simple'
import useItemChildrenQuery from '@services/api/resources/item-children'
import usePatchEntrySimple from '@services/api/resources/patch-entry-query-simple'
import { captureException } from '@services/exceptions/capture-exception'
import { useDraggableStore } from '@services/stores/draggable/draggable'
import { getInitialValues } from '@services/tools/api-resources/formik'

const RowList = ({ pageId, section }: RowListProps) => {
  const { t: translateResource } = useTranslation('apiResources', { keyPrefix: 'sections' })
  const { isDraggable, setIsDraggable } = useDraggableStore()
  const [open, setOpen] = useState(false)
  const [openEdit, setOpenEdit] = useState(false)
  const [sectionRow, setSectionRow] = useState<SectionRow>()
  const { mutateAsync: createSectionRowEntry } = useCreateEntry({ path: 'section-rows' })
  const { mutateAsync: patchResourceEntry } = usePatchEntrySimple()
  const { mutateAsync: deleteEntry } = useDeleteEntrySimple()
  const definition = useApiResource('section-rows')
  const requestFields = definition?.methods.post.getRequestFields() ?? []
  const initialValues = getInitialValues(requestFields, [])
  const sectionRowDefinition = useApiResource('section-rows/{uid}')

  const {
    data: {
      data: rows
    } = {},
    isFetching,
    refetch
  } = useItemChildrenQuery<SectionRow>({
    itemId: section.uid,
    path: 'sections/{uid}/rows'
  })

  const reload = () => {
    refetch().catch(captureException)
  }

  const createValues = (hit) => {
    const values = { ...initialValues }
    values.section = `/api/sections/${section.uid}`
    values.priority = rows ? (rows.length + 1) : 0
    values.page = null
    values.cover = null
    values.experience = null
    values.artist = null

    if (hit.type === 'artist') {
      values.artist = `/api/artists/${hit.uid}`
      values.type = 'artist'
    } else if (['artwork', 'course', 'activity', 'attraction', 'hotel'].includes(hit.type)) {
      values.type = 'experience'
      values.experience = `/api/experiences/${hit.uid}`
    } else if (hit.type === 'page') {
      values.type = 'page'
      values.page = `/api/pages/${hit.uid}`
    }

    return values
  }

  const onSelect = (hits) => {
    const promises = hits.map(async (hit) => {
      const values = createValues(hit)

      return await createSectionRowEntry(values)
    })

    Promise.all(promises)
      .then(() => {
        reload()
        setOpen(false)
      })
      .catch(captureException)
  }

  const onDelete = (sectionRow) => {
    deleteEntry({
      id: sectionRow.uid,
      path: 'section-rows/{uid}'
    }).then(() => {
      reload()
    }).catch(captureException)
  }

  const onImageEdit = (sectionRowSelected) => {
    setSectionRow(sectionRowSelected)
    setOpenEdit(true)
  }

  const onEditSuccess = () => {
    setOpenEdit(false)
    reload()
  }

  const onAddClick = () => {
    setOpen(true)
  }

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return
    }

    const destination: DroppableType = result.destination
    const source: DroppableType = result.source

    // Prevent move on the same place
    if (source.droppableId === destination.droppableId && source.index === destination.index) {
      return
    }

    setIsDraggable(false)

    if (result.type === 'column') {
      // reorder courses
      patchResourceEntry({
        data: {
          priority: result.destination.index
        },
        id: result.draggableId,
        path: 'section-rows/{uid}'
      }).then(() => {
        reload()
        setIsDraggable(true)
      }).catch(captureException)
    }
  }

  const renderAddButton = () => {
    if (SectionLimited.includes(section.type) && rows && rows.length === 1) {
      return <></>
    }

    return (
      <div className='flex gap-1 items-start px-2 py-10'>
        <button className='flex flex-col justify-center items-center border bg-white border-gray-200 rounded-lg p-1 w-[200px]' onClick={onAddClick}>
          <div className='text-gray-900 text-sm px-5 py-[45px] text-center'>{translateResource('addSectionRow')}</div>

          <div className='w-full flex items-center justify-center bg-gray-200 rounded-lg p-1'>
            <PlusIcon className='w-4 h-4' />
          </div>
        </button>
      </div>
    )
  }

  return (
    <>
      {isFetching
        ? (
          <SkeletonLoader type='section-row' />
        )
        : (
          <div className='flex items-start'>
            {!!rows?.length && !isFetching &&
              (
                <DragDropContext onDragEnd={onDragEnd}>
                  <Droppable direction='horizontal' droppableId={section.uid} type='column'>
                    {(provided) => (
                      <div
                        {...provided.droppableProps}
                        className='flex overflow-x-scroll scrollbar scrollbar-track-gray-100 scrollbar-thumb-gray-300 scrollbar-rounded scrollbar-height gap-1 px-2 py-10 items-start'
                        ref={provided.innerRef}
                      >
                        {rows?.map((sectionRow) => (
                          <Draggable draggableId={sectionRow.uid} index={sectionRow.priority} isDragDisabled={!isDraggable} key={sectionRow.uid}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`${snapshot.isDragging ? 'drop-shadow-md' : 'shadow-none'}`}
                              >
                                {sectionRow.type === 'experience' && sectionRow.experience && (
                                  <Experience
                                    experience={sectionRow.experience}
                                    handleDeleteClick={() => {
                                      onDelete(sectionRow)
                                    }}
                                    handleEditImageClick={() => {
                                      onImageEdit(sectionRow)
                                    }}
                                    sectionRow={sectionRow}
                                  />
                                )}

                                {sectionRow.type === 'artist' && sectionRow.artist && (
                                  <Artist
                                    artist={sectionRow.artist}
                                    handleDeleteClick={() => {
                                      onDelete(sectionRow)
                                    }}
                                    handleEditImageClick={() => {
                                      onImageEdit(sectionRow)
                                    }}
                                    sectionRow={sectionRow}
                                  />
                                )}

                                {sectionRow.type === 'page' && sectionRow.page && (
                                  <SubPage
                                    handleDeleteClick={() => {
                                      onDelete(sectionRow)
                                    }}
                                    handleEditImageClick={() => {
                                      onImageEdit(sectionRow)
                                    }}
                                    page={sectionRow.page}
                                    sectionRow={sectionRow}
                                  />
                                )}

                              </div>
                            )}
                          </Draggable>
                        ))}

                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                </DragDropContext>
              )}

            {renderAddButton()}
          </div>
        )
      }
      <GlobalSearch indexes={['hotel', 'activity', 'attraction', 'artwork', 'artist', 'page', 'course']} isOpened={open} multiple onSelectCallback={onSelect} setOpened={setOpen} />

      {sectionRowDefinition && sectionRow && (
        <Modal center open={openEdit} setOpen={setOpenEdit} size='large' title={translateResource('edit')}>
          <ApiResourceEdit definition={sectionRowDefinition} fieldsToDisplay={['cover']} id={sectionRow.uid} isModal onEdit={onEditSuccess} />
        </Modal>
      )}
    </>
  )
}

export default RowList
